/* eslint-disable no-restricted-imports */
import axios from "axios"
import React, {useEffect, useState} from "react"
import CreateUserButton from "../../Common/CreateNewButton"
import UsersList from "../../Users/UsersList.js"
import {
    getUsers,
    saveUsers,
    getUserByID,
    createUser,
    updateUser,
    getUsersGeneralInfo
} from "../../Users/_axios/userCrud"
import CreateUserFormDialog from "../../Users/CreateUserFormDialog"
import {withRouter} from "react-router-dom"
import FilterPanel from "./FilterPanel"
import {customerUserRoles} from "../../Users/rolesTranslation"
import {limitTypes} from "../../Users/limitTypesTranslation"
import UploadUsersFormDialog from "./UploadUsersFormDialog"
import UpdateUsersFormDialog from "./UpdateUsersFormDialog" 
import FreezeFormDialog from "./FreezeFormDialog"
import {Button} from "@material-ui/core"
import {FormattedMessage, injectIntl} from "react-intl"
import UserDetails from "../../Users/UserDetails"
import {useDispatch} from "react-redux"
import {handleApiError} from "../../../../redux/snackbar/snackbarHandlers"
import {getCustomerLocations} from "../_axios/customerCrud"
import DishLoader from "../../Common/DishLoader"
import ExportButton from "../../../modules/Reports/ExportButton"
import {formatDateForApi, formatDate} from "../../../modules/Common/momentFunctions"
import {getRoleID} from "../../Users/rolesTranslation"

function UserPanel({
                       intl,
                       userRole,
                       departments,
                       selectedCustomer,
                       ...props
                   }) {
    const dispatch = useDispatch()

    const [usersData, setUsersData] = useState({
        usersCount: 0,
        displayedUsers: [],
        isLoading: true
    })
    const [locationsData, setLocationsData] = useState({
        locations: [],
        isLoading: true
    })
    const [selectedUser, setSelectedUser] = useState({UserID: ""})
    const [showFreezeForm, setShowFreezeForm] = useState(false)
    const [freezeLoadingForm, setFreezeLoadingForm] = useState(false)
    const [settings, setSettings] = useState({})
    const [searchParams, setSearchParams] = useState({})
    const [paginationState, setPaginationState] = useState()
    const [refreshCount, setRefreshCount] = useState(0)
    const [showCreateUserPanel, setShowCreateUserPanel] = useState(false)
    const [showUploadUsersPanel, setShowUploadUsersPanel] = useState(false)
    const [showUpdateUsersPanel, setShowUpdateUsersPanel] = useState(false)

    function handleSearchParamsSave(changes) {
        const newParams = {...searchParams, ...changes}
        setSearchParams(newParams)
    }

    function handlePaginationStateChange(changes) {
        const newState = {...paginationState, ...changes}
        setPaginationState(newState)
    }

    function selectUser(UserID) {
        if (selectedUser.UserID !== UserID) {
            setSelectedUser({UserID})
        }
    }

    function saveUser(newUserData) {
        return updateUser(newUserData, newUserData.UserID)
    }

    function uploadBulk(uploadedUsers) {
        return axios.post(
            `/Customer/${selectedCustomer.CustomerID}/Users​/BulkUpload`,
            uploadedUsers
        )
    }

    function updateBulk(uploadedUsers) {
        return axios.post(
            `/Customer/${selectedCustomer.CustomerID}/Users​/BulkUpdate`,
            uploadedUsers
        )
    }

    function fetchLocations(cancelToken) {
        getCustomerLocations(selectedCustomer.CustomerID, cancelToken.token)
            .then(({data}) => {
                setLocationsData({locations: data, isLoading: false})
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_LOCATIONS"
                    })
                )
            )
    }

    function fetchUserByID(cancelToken) {
        setSelectedUser({...selectedUser, isLoading: true})
        getUserByID(selectedUser.UserID, cancelToken.token)
            .then(({data}) => {
                setSelectedUser(data)
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_USER_BY_ID"
                    })
                )
            )
    }

    function fetchUsers(cancelToken) {
        const {page, rowsPerPage, orderBy, sortBy} = paginationState
        const {text, role, location, department, active} = searchParams

        const params = {
            offset: page * rowsPerPage,
            limit: rowsPerPage,
            sortBy,
            orderBy
        }
        params.customerid = selectedCustomer.CustomerID
        if (text !== "") {
            params.text = text
        }
        if (location) {
            params.locationid = location
        }
        if (department) {
            params.departmentid = department
        }
        if (role !== "all") {
            params.role = role
        }
        if (active !== "all") {
            params.active = active === "active" ? true : false
        }

        props.history.push({
            pathname: `/customer/${selectedCustomer.CustomerID}`,
            search: "?" + new URLSearchParams(params).toString()
        })

        setUsersData({...usersData, isLoading: true})
        getUsers(params, cancelToken.token)
            .then(({data}) => {
                setUsersData({
                    usersCount: data.total,
                    displayedUsers: data.items,
                    isLoading: false
                })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_USERS"
                    })
                )
            )
    }

    function formatExportData(data) {
        return data.map(item => {
            const formattedItem = {}
            formattedItem[
                intl.formatMessage({
                    id: "EXPORT_FORM.CUSTOMER_NAME_NAME_FIELD.LABEL"
                })
                ] = (item.Customer ? item.Customer.Name : '')
            formattedItem[
                intl.formatMessage({
                    id: "EXPORT_FORM.LOCATION_NAME_FIELD.LABEL"
                })
                ] = item.Locations.map(location => location.Name).join(',')
            formattedItem[
                intl.formatMessage({
                    id: "EXPORT_FORM.FIRST_NAME_FIELD.LABEL"
                })
                ] = item.FirstName
            formattedItem[
                intl.formatMessage({
                    id: "EXPORT_FORM.LAST_NAME_FIELD.LABEL"
                })
                ] = item.LastName
            formattedItem[
                intl.formatMessage({
                    id: "EXPORT_FORM.DEPARTMENT_NAME_FIELD.LABEL"
                })
                ] = item.DepartmentName
            formattedItem[
                intl.formatMessage({
                    id: "EXPORT_FORM.ROLE_FIELD.LABEL"
                })
                ] = intl.formatMessage({
                id: getRoleID(item.Role)
            })
            formattedItem[
                intl.formatMessage({
                    id: "EXPORT_FORM.MOBILE_FIELD.LABEL"
                })
                ] = item.Mobile
            formattedItem[
                intl.formatMessage({
                    id: "EXPORT_FORM.EMAIL_FIELD.LABEL"
                })
                ] = item.Email
            formattedItem[
                intl.formatMessage({
                    id: "EXPORT_FORM.EXTERNAL_ID_FIELD.LABEL"
                })
                ] = item.ExternalID
            formattedItem[
                intl.formatMessage({
                    id: "EXPORT_FORM.REGISTRATION_DATE_FIELD.LABEL"
                })
                ] = formatDate(item.RegistrationDate)
            formattedItem[
                intl.formatMessage({
                    id: "EXPORT_FORM.CREATOR_NAME_FIELD.LABEL"
                })
                ] = item.CreatorName;
            formattedItem[
                intl.formatMessage({
                    id: "EXPORT_FORM.INVOICE_NAME_FIELD.LABEL"
                })
                ] = item.InvoiceName
            formattedItem[
                intl.formatMessage({
                    id: "EXPORT_FORM.INVOICE_NAME_FIELD.FREEZE"
                })
                ] = !item.Active ? intl.formatMessage({
                    id: "EXPORT_FORM.INVOICE_NAME_FIELD.FREEZE"
                }) : intl.formatMessage({
                    id: "EXPORT_FORM.INVOICE_NAME_FIELD.ACTIVE"
                })
            return formattedItem
        })
    }

    function formatExportCSVData(data) {
        return data.map(item => {
            const formattedItem = {}
            formattedItem[
                intl.formatMessage({
                    id: "EXPORT_FORM.FIRST_NAME_FIELD.LABEL"
                })
            ] = item.FirstName
            formattedItem[
                intl.formatMessage({
                    id: "EXPORT_FORM.LAST_NAME_FIELD.LABEL"
                })
            ] = item.LastName
            formattedItem[
                intl.formatMessage({
                    id: "EXPORT_FORM.MOBILE_FIELD.LABEL"
                })
            ] = item.Mobile
            formattedItem[
                intl.formatMessage({
                    id: "EXPORT_FORM.EMAIL_FIELD.LABEL"
                })
            ] = item.Email
            formattedItem[
                intl.formatMessage({
                    id: "EXPORT_FORM.CUSTOMER_NAME_NAME_FIELD.LABEL"
                })
            ] = (item.Customer ? item.Customer.Name : '')
            return formattedItem
        })
    }

    function handleExport(response) {
        const {page, rowsPerPage, orderBy, sortBy} = paginationState
        const {text, role, location, department, active} = searchParams
        const params = {
            sortBy,
            orderBy
        }
        params.customerid = selectedCustomer.CustomerID
        if (text !== "") {
            params.text = text
        }
        if (location) {
            params.locationid = location
        }
        if (department) {
            params.departmentid = department
        }
        if (role !== "all") {
            params.role = role
        }
        if (active !== "all") {
            params.active = active === "active" ? true : false
        }
        const cancelToken = axios.CancelToken.source();
        getUsers(params, cancelToken.token)
            .then(({data}) => {
                response(formatExportData(data.items), `${intl.formatMessage({
                    id: "EXPORT_FILE_NAME.REPORT"
                })}_${formatDate(new Date())}`)
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_USERS"
                    })
                )
            )
    }

    function handleExportCSV(response) {
        const {page, rowsPerPage, orderBy, sortBy} = paginationState
        const params = {
            sortBy,
            orderBy
        }
        params.customerid = selectedCustomer.CustomerID
        params.active = true;
        const cancelToken = axios.CancelToken.source();
        getUsers(params, cancelToken.token)
            .then(({ data }) => {
                response(formatExportCSVData(data.items), `${intl.formatMessage({
                    id: "EXPORT_FILE_NAME.REPORT"
                })}_${formatDate(new Date())}`)
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_USERS"
                    })
                )
            )
    }

    function updateUsers(newUserData) {
        if (
            (searchParams.active === "active" && !newUserData.Active) ||
            (searchParams.active === "not active" && newUserData.Active)
        ) {
            handleRemoveUser(newUserData.UserID)
            return
        }

        const users = [...usersData.displayedUsers]
        const changedUserIndex = users.findIndex(
            users => users.UserID === newUserData.UserID
        )
        if (changedUserIndex !== -1) {
            users[changedUserIndex] = {...newUserData}
            setUsersData({
                ...usersData,
                displayedUsers: [...users]
            })
            setSelectedUser({...newUserData})
        }
    }

    function handleRemoveUser(id) {
        setUsersData({
            ...usersData,
            usersCount: usersData.usersCount - 1,
            displayedUsers: usersData.displayedUsers.filter(
                user => user.UserID !== id
            )
        })
        setSelectedUser({UserID: ""})
    }

    function addUser(newUser) {
        return createUser(newUser)
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)

        const rowsPerPageValue = urlParams.get("limit")
        const rowsPerPage = rowsPerPageValue ? +rowsPerPageValue : 50
        const pageValue = urlParams.get("offset")
        const page = pageValue ? +pageValue / rowsPerPage : 0
        const sortBy = urlParams.get("sortBy") || "FirstName"
        const orderBy = urlParams.get("orderBy") || "asc"
        const text = urlParams.get("text") || ""
        const role = urlParams.get("role") || "all"
        const customer = selectedCustomer
        const location = urlParams.get("locationid") || ""
        const department = urlParams.get("departmentid") || ""
        const activeValue = urlParams.get("active")
        const active = activeValue
            ? "active"
            : activeValue === false
                ? "not active"
                : "all"
        setSearchParams({
            text,
            role,
            customer,
            location,
            department,
            active
        })
        setPaginationState({
            page,
            rowsPerPage,
            orderBy,
            sortBy
        })
    }, [])

    useEffect(() => {
        if (searchParams.customer && paginationState.rowsPerPage) {
            const cancelToken = axios.CancelToken.source()
            fetchUsers(cancelToken)
            return () => cancelToken.cancel()
        }
    }, [paginationState, searchParams, refreshCount])

    useEffect(() => {
        if (selectedUser.UserID) {
            const userDetails = usersData.displayedUsers.find(
                user => user.UserID === selectedUser.UserID
            )
            if (!userDetails) {
                const cancelToken = axios.CancelToken.source()
                fetchUserByID(cancelToken)
                return () => cancelToken.cancel()
            } else {
                setSelectedUser(userDetails)
            }
        }
    }, [selectedUser.UserID])

    useEffect(() => {
        const cancelToken = axios.CancelToken.source()
        fetchLocations(cancelToken)
        return () => cancelToken.cancel()
    }, [])

    useEffect(() => {
        return loadUserSettings();
    }, [])

    function loadUserSettings() {
        const cancelToken = axios.CancelToken.source()
        fetchUsersGeneralInfo(cancelToken)
        return () => cancelToken.cancel()
    }

    function fetchUsersGeneralInfo(cancelToken) {
        getUsersGeneralInfo({}, selectedCustomer.CustomerID, cancelToken.token)
            .then(({data}) => {
                setSettings(data)
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_USER_INFO"
                    })
                )
            )
    }

    function getAvailableUserRoles() {
        return customerUserRoles
    }

    function getAvailableLimitTypes() {
        return limitTypes
    }

    if (locationsData.isLoading) {
        return <DishLoader/>
    }

    function freezeHandle() {
        setFreezeLoadingForm(true)
        const cancelToken = axios.CancelToken.source();
        saveUsers({
            active: !settings?.activeAll
        }, selectedCustomer.CustomerID, cancelToken.token)
            .then(({data}) => {
                setSettings({
                    ...settings,
                    activeAll: !settings?.activeAll
                });
                setShowFreezeForm(false);
                setFreezeLoadingForm(false);
                const cancelToken = axios.CancelToken.source()
                fetchUsers(cancelToken)
                return () => cancelToken.cancel()
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_UPDATE_USERS"
                    })
                )
            )
    }

    return (
        <>
            <UploadUsersFormDialog
                show={showUploadUsersPanel}
                allDepartments={selectedCustomer.Departments}
                allLocations={locationsData.locations}
                handleSubmit={uploadBulk}
                closeDialog={() => setShowUploadUsersPanel(false)}
            />
            <UpdateUsersFormDialog
                show={showUpdateUsersPanel}
                allDepartments={selectedCustomer.Departments}
                allLocations={locationsData.locations}
                handleSubmit={updateBulk}
                closeDialog={() => setShowUpdateUsersPanel(false)}
            />
            <CreateUserButton onClick={() => setShowCreateUserPanel(true)}/>
            <CreateUserFormDialog
                show={showCreateUserPanel}
                closeDialog={userID => {
                    if (userID) {
                        setRefreshCount(refreshCount + 1)
                    }
                    setShowCreateUserPanel(false)
                }}
                submitData={addUser}
                variant={"customer"}
                allCustomers={[
                    {...selectedCustomer, Locations: locationsData.locations}
                ]}
                availableUserRoles={getAvailableUserRoles()}
            />
            {searchParams.customer && (
                <FilterPanel
                    onSearchParamsSave={handleSearchParamsSave}
                    initialSearchParams={searchParams}
                    allLocations={locationsData.locations}
                />
            )}
            {
                ["Admin", "Owner"].includes(userRole) && (
                    <div className="row">
                        <Button
                            variant="outlined"
                            type="button"
                            size="large"
                            className='m-5'
                            onClick={() => setShowFreezeForm(true)}
                        >
                            {
                                settings?.activeAll && (
                                    <FormattedMessage id="USER_FORM.FREEZE_ALL"/>
                                )
                            }
                            {
                                !settings?.activeAll && (
                                    <FormattedMessage id="USER_FORM.UN_FREEZE_ALL"/>
                                )
                            }
                        </Button>
                        <FreezeFormDialog
                            show={showFreezeForm}
                            title={<FormattedMessage id="FREEZE_FORM.TITLE"/>}
                            closeDialog={() => {
                                setShowFreezeForm(false);
                            }}
                            submitData={ev => freezeHandle(ev)}
                            isSubmitting={freezeLoadingForm}
                            intl={intl}
                            active={settings?.activeAll}
                        />
                        <div className="d-inline mt-2">
                            <ExportButton exportData={usersData.displayedUsers}
                                fileName={`${intl.formatMessage({
                                    id: "EXPORT_FILE_NAME.REPORT"
                                })}_${formatDate(new Date())}`}
                                onHandleData={handleExportCSV}
                                isCSV={true}
                                formatMessageId="GET_ALL_ACTIVE_BUTTON_CSV"/>
                        </div>
                    </div>
                )
            }
            <div className="row">
                <div className="col-md-6">
                    <UsersList
                        paginationState={paginationState}
                        onChange={handlePaginationStateChange}
                        usersData={usersData}
                        selected={selectedUser.UserID}
                        setSelected={selectUser}
                    />
                    <Button
                        variant="outlined"
                        type="button"
                        size="large"
                        onClick={() => setShowUploadUsersPanel(true)}
                    >
                        <FormattedMessage id="UPLOAD_FORM.UPLOAD_BUTTON"/>
                    </Button>
                    <div className="mt-3">
                        <Button
                            variant="outlined"
                            type="button"
                            size="large"
                            onClick={() => setShowUpdateUsersPanel(true)}
                        >
                            <FormattedMessage id="UPLOAD_FORM.UPDATE_FROM_FILE_BUTTON" />
                        </Button>
                    </div>

                    <div>
                        {
                            usersData && usersData.usersCount > 0 && (
                                <div className="d-inline">
                                    <ExportButton exportData={usersData.displayedUsers}
                                                  fileName={`${intl.formatMessage({
                                                      id: "EXPORT_FILE_NAME.REPORT"
                                                  })}_${formatDate(new Date())}`}
                                                  onHandleData={handleExport}/>
                                </div>
                            )}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card card-custom gutter-b">
                        <div className="card-body">
                            <UserDetails
                                submitData={saveUser}
                                updateUsers={updateUsers}
                                userData={selectedUser}
                                afterSubmit={(ev) => loadUserSettings()}
                                allCustomers={[
                                    {...selectedCustomer, Locations: locationsData.locations}
                                ]}
                                availableUserRoles={getAvailableUserRoles()}
                                limitTypes={getAvailableLimitTypes()}
                                disableCompanyCreditCheckbox={userRole !== "Owner"}
                                formVariant={userRole.toLowerCase()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default injectIntl(withRouter(UserPanel))
