/* eslint-disable no-restricted-imports */
import DateFnsUtils from "@date-io/date-fns"
import {
    Button,
    Checkbox,
    FilledInput,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select
} from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import LockOpenIcon from "@material-ui/icons/LockOpen"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { he } from "date-fns/esm/locale"
import { useFormik } from "formik"
import React, { useState } from "react"
import InputMask from "react-input-mask"
import { FormattedMessage, injectIntl } from "react-intl"
import { useDispatch } from "react-redux"
import * as Yup from "yup"
import { handleApiError } from "../../../redux/snackbar/snackbarHandlers"
import { actions as snackbarActions } from "../../../redux/snackbar/snackbarRedux"
import ConfirmationDialog from "../Common/ConfirmationDialog"
import DishLoader from "../Common/DishLoader"
import { unlockUser } from "./_axios/userCrud"
import { getLimitTypeID } from "./limitTypesTranslation"
import {
    customerUserRoles,
    getRoleID,
    supplierUserRoles,
    allUserRoles
} from "./rolesTranslation"

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: 591
    },
    textField: {
        margin: theme.spacing(1, 0)
    },
    buttonRow: {
        display: "flex",
        justifyContent: "space-between"
    },
    saveButton: {
        marginLeft: theme.spacing(1)
    },
    unlockButton: {
        marginLeft: "auto"
    },
    textRight: {
        textAlign: "left"
    }
}))

function UserDetails({
                         intl,
                         submitData,
                         updateUsers,
                         userData,
                         allCustomers,
                         allSuppliers,
                         availableUserRoles,//deprecated
                         limitTypes,
                         disableCompanyCreditCheckbox,
                         formVariant,
                         afterSubmit
                     }) {
    const dispatch = useDispatch()
    const classes = useStyles()

    const [deactivateDialogData, setDeactivateDialogData] = useState({
        show: false,
        isSubmitting: false
    })
    const [activateLimits, setActivateLimits] = useState({
        show: false,
    })

    const initialValues = {
        firstName: userData.FirstName || "",
        lastName: userData.LastName || "",
        email: userData.Email || "",
        extraEmail: userData.ExtraEmail || "",
        startDate: userData.StartDate || null,
        phone: userData.Mobile || "",
        role: userData.Role || "",
        invoice: userData.InvoiceName || "",
        customer: userData.CustomerID ? userData.CustomerID : "",
        locations: userData.Locations
            ? userData.Locations.map(location => location.LocationID)
            : [],
        department: userData.DepartmentID ? userData.DepartmentID : "",
        supplier: userData.SupplierID ? userData.SupplierID : "",
        permanentCode: userData.PermanentCode || "",
        externalID: userData.ExternalID || "",
        companyCredit: userData.CompanyCreditSupport || false,
        limitType: userData.LimitType || "",
        limitValue: userData.LimitValue || ""
    }
    const IsOwnerUserAndEditorNotOwner = userData.Role === "Owner" && formVariant !== "owner";
    const IsAdminUserAndEditorNotOwner = (userData.Role === "Admin" || userData.Role === "Customer Admin" || userData.Role === "Supplier Admin") && formVariant !== "owner";

    const firstNameValidationSchema = Yup.string()
        .min(
            2,
            intl.formatMessage({id: "AUTH.VALIDATION.MIN_LENGTH_FIELD"}, {min: 2})
        )
        .max(
            50,
            intl.formatMessage(
                {id: "AUTH.VALIDATION.MAX_LENGTH_FIELD"},
                {max: 50}
            )
        )
        .required(
            intl.formatMessage({
                id: "VALIDATION.REQUIRED_FIELD"
            })
        )

    const lastNameValidationSchema = Yup.string()
        .min(
            2,
            intl.formatMessage({id: "AUTH.VALIDATION.MIN_LENGTH_FIELD"}, {min: 2})
        )
        .max(
            50,
            intl.formatMessage(
                {id: "AUTH.VALIDATION.MAX_LENGTH_FIELD"},
                {max: 50}
            )
        )
        .required(
            intl.formatMessage({
                id: "VALIDATION.REQUIRED_FIELD"
            })
        )

    const extraEmailValidationSchema = Yup.string()
        .email(
            intl.formatMessage({
                id: "AUTH.VALIDATION.WRONG_EMAIL_FORMAT"
            })
        )
        .min(
            2,
            intl.formatMessage({id: "AUTH.VALIDATION.MIN_LENGTH_FIELD"}, {min: 2})
        )
        .max(
            50,
            intl.formatMessage(
                {id: "AUTH.VALIDATION.MAX_LENGTH_FIELD"},
                {max: 50}
            )
        )
    
    const emailValidationSchema = extraEmailValidationSchema
        .when("phone", {
            is: phone => {
                return !phone
            },
            then: Yup.string().required(
                intl.formatMessage({
                    id: "VALIDATION.REQUIRED_FIELD"
                })
            )
        })

    const phoneValidationSchema = Yup.string()
        .matches(
            /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
            intl.formatMessage({
                id: "AUTH.VALIDATION.WRONG_PHONE_FORMAT"
            })
        )
        .when("email", {
            is: email => {
                return !email
            },
            then: Yup.string().required(
                intl.formatMessage({
                    id: "VALIDATION.REQUIRED_FIELD"
                })
            )
        })

    const roleValidationSchema = Yup.string().required(
        intl.formatMessage({
            id: "VALIDATION.REQUIRED_FIELD"
        })
    )

    const customerValidationSchema = Yup.string().when("role", {
        is: role => {
            return (formVariant === "admin" || formVariant === "owner") && customerUserRoles.includes(role)
        },
        then: Yup.string().required(
            intl.formatMessage({
                id: "VALIDATION.REQUIRED_FIELD"
            })
        )
    })

    const supplierValidationSchema = Yup.string().when("role", {
        is: role => {
            return (formVariant === "admin" || formVariant === "owner") && supplierUserRoles.includes(role)
        },
        then: Yup.string().required(
            intl.formatMessage({
                id: "VALIDATION.REQUIRED_FIELD"
            })
        )
    })

    const locationsValidationSchema = Yup.array().when("role", {
        is: role => {
            return (
                ["admin","owner", "customer"].includes(formVariant) &&
                customerUserRoles.includes(role)
            )
        },
        then: Yup.array().test(
            "NotEmpty",
            intl.formatMessage({
                id: "VALIDATION.REQUIRED_FIELD"
            }),
            value => {
                return value.length > 0
            }
        )
    })

    const departmentValidationSchema = Yup.string().when("role", {
        is: role => {
            return (
                ["admin","owner", "customer"].includes(formVariant) &&
                customerUserRoles.includes(role)
            )
        },
        then: Yup.string().required(
            intl.formatMessage({
                id: "VALIDATION.REQUIRED_FIELD"
            })
        )
    })

    const oneTimePasswordValidationSchema = Yup.string().test(
        "otpFormat",
        intl.formatMessage({
            id: "AUTH.VALIDATION.WRONG_OTP_FORMAT"
        }),
        value => {
            if (value) {
                return value.replace(/\D/g, "").length === 6
            }
            return true
        }
    )

    const validationSchema = Yup.object().shape(
        {
            firstName: firstNameValidationSchema,
            lastName: lastNameValidationSchema,
            email: emailValidationSchema,
            phone: phoneValidationSchema,
            extraEmail: extraEmailValidationSchema,
            role: roleValidationSchema,
            customer: customerValidationSchema,
            locations: locationsValidationSchema,
            department: departmentValidationSchema,
            supplier: supplierValidationSchema,
            permanentCode: oneTimePasswordValidationSchema
        },
        [
            "email",
            "phone",
            "extraEmail",
            "role",
            "customer",
            "locations",
            "department",
            "supplier",
            "firstName",
            "lastName",
            "permanentCode"
        ]
    )

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, {setSubmitting, setFieldError, resetForm}) => {
            const {
                email,
                extraEmail,
                startDate,
                phone,
                role,
                invoice,
                externalID,
                customer,
                locations,
                department,
                supplier,
                firstName,
                lastName,
                permanentCode,
                companyCredit,
                limitType,
                limitValue
            } = values
            const newUser = {
                UserID: userData.UserID,
                IsLocked: userData.IsLocked,
                FirstName: firstName,
                LastName: lastName,
                Email: email,
                ExtraEmail: extraEmail,
                StartDate: startDate,
                Mobile: phone,
                Role: role,
                InvoiceName: invoice,
                ExternalID: externalID,
                Active: userData.Active,
                PermanentCode: permanentCode.replace(/\D/g, "") || null,
                CompanyCreditSupport: companyCredit,
                LimitType: companyCredit ? limitType : null,
                LimitValue: companyCredit && limitType !== "No limitation" ? limitValue : null
            }
            if (customerUserRoles.includes(role)) {
                newUser.CustomerID = customer
                newUser.Locations = allCustomers
                    .find(el => el.CustomerID === customer)
                    .Locations.filter(location => locations.includes(location.LocationID))
                newUser.DepartmentID = department

                newUser.SupplierID = null
            } else if (supplierUserRoles.includes(role)) {
                newUser.SupplierID = supplier

                newUser.CustomerID = null
                newUser.Locations = []
                newUser.DepartmentID = null
            } else {
                newUser.SupplierID = null
                newUser.CustomerID = null
                newUser.Locations = []
                newUser.DepartmentID = null
            }
            setSubmitting(true)
            submitData(newUser)
                .then(() => {
                    setSubmitting(false)
                    resetForm()
                    dispatch(
                        snackbarActions.setSnackbarData(
                            intl.formatMessage({
                                id: "API.USER.EDIT_SUCCESS"
                            })
                        )
                    )
                    updateUsers(newUser)
                    if (afterSubmit) {
                        afterSubmit()
                    }
                })
                .catch(error => {
                    setSubmitting(false)
                    if (error.response && error.response.status === 409) {
                        const {emailTaken, mobileTaken} = error.response.data

                        if (emailTaken) {
                            setFieldError(
                                "email",
                                intl.formatMessage({
                                    id: "ADMIN_USERS.VALIDATION.EMAIL_USED"
                                })
                            )
                        }
                        if (mobileTaken) {
                            setFieldError(
                                "phone",
                                intl.formatMessage({
                                    id: "ADMIN_USERS.VALIDATION.MOBILE_USED"
                                })
                            )
                        }
                    } else {
                        handleApiError(
                            dispatch,
                            error,
                            intl.formatMessage({
                                id: "API.ERROR.FAILED_TO_UPDATE_USER"
                            })
                        )
                    }
                })
        }
    })

    function checkForError(fieldName) {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true
        }
        return false
    }

    function renderErrors(fieldName) {
        return checkForError(fieldName) ? (
            <span style={{color: "#F018A6"}}>{formik.errors[fieldName]}</span>
        ) : null
    }

    if (userData.isLoading) {
        return <DishLoader/>
    }
    if (Object.keys(userData).length <= 1) {
        return <div className={classes.root}/>
    }


    function isRoleNotAvailable() {
        var role = formik.getFieldProps("role");
        if (formVariant === "owner") {
            return false;
        } else if (role.value === "Owner" || role.value === "Admin" || role.value === "Supplier Admin" || role.value === "Customer Admin") {
            return true
        }
        
        return false;
    }

    function handleUnlock() {
        unlockUser(userData.UserID)
            .then(() => {
                updateUsers({...userData, IsLocked: false})
                if (afterSubmit) {
                    afterSubmit()
                }
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_UNLOCK_USER"
                    })
                )
            )
    }

    function renderLocationAndDepartmentFields() {
        return formik.values.customer !== "" ? (
            <>
                <FormControl
                    variant="filled"
                    className={classes.textField}
                    fullWidth
                    error={checkForError("locations")}
                >
                    <InputLabel htmlFor="locations-select">
                        <FormattedMessage id="ADMIN_USERS.CREATE_FORM.LOCATION_FIELD.LABEL"/>
                    </InputLabel>
                    <Select
                        {...formik.getFieldProps("locations")}
                        multiple
                        input={<FilledInput name="locations" id="locations-select"/>}
                    >
                        {formik.values.customer &&
                            allCustomers
                                .find(
                                    customer => customer.CustomerID === formik.values.customer
                                )
                                .Locations.map(location => (
                                <MenuItem
                                    key={location.LocationID}
                                    value={location.LocationID}
                                >
                                    {location.Name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                {renderErrors("locations")}
                <FormControl
                    variant="filled"
                    className={classes.textField}
                    fullWidth
                    error={checkForError("department")}
                >
                    <InputLabel htmlFor="department-select">
                        <FormattedMessage id="ADMIN_USERS.CREATE_FORM.DEPARTMENT_FIELD.LABEL"/>
                    </InputLabel>
                    <Select
                        {...formik.getFieldProps("department")}
                        input={<FilledInput name="department" id="department-select"/>}
                    >
                        {formik.values.customer &&
                            allCustomers
                                .find(
                                    customer => customer.CustomerID === formik.values.customer
                                )
                                .Departments.map(department => (
                                <MenuItem
                                    key={department.DepartmentID}
                                    value={department.DepartmentID}
                                >
                                    {department.Name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>

                {renderErrors("department")}
            </>
        ) : (
            <div style={{height: 136.88}}/>
        )
    }

    const handleAcceptChangeActive = () => {
        setDeactivateDialogData({...deactivateDialogData, isSubmitting: true})
        const newUser = {...userData, Active: !userData.Active}

        submitData(newUser)
            .then(() => {
                setDeactivateDialogData({show: false, isSubmitting: false})
                updateUsers(newUser)
                if (afterSubmit) {
                    afterSubmit()
                }
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_UPDATE_USER"
                    })
                )
            )
    }
    const handleActivateLimits = () => {
        setActivateLimits(true)
    }

    return (
        <>

            <ConfirmationDialog
                show={deactivateDialogData.show}
                onSubmit={handleAcceptChangeActive}
                onClose={() => setDeactivateDialogData({show: false})}
                isSubmitting={deactivateDialogData.isSubmitting}
                dialogTitle={<FormattedMessage id="DEACTIVATE_USER_DIALOG.TITLE"/>}
                contentText={`${intl.formatMessage({
                    id: "DEACTIVATE_USER_DIALOG.TEXT"
                })} ${userData.FirstName} ${userData.LastName}?`}
            />
            <div className={classes.root}>
                <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
                    <TextField
                        id="first-name"
                        name="firstName"
                        label={intl.formatMessage({
                            id: "ADMIN_USERS.CREATE_FORM.FIRST_NAME_FIELD.LABEL"
                        })}
                        className={classes.textField}
                        margin="normal"
                        variant="filled"
                        fullWidth
                        {...formik.getFieldProps("firstName")}
                        error={checkForError("firstName")}
                    />
                    {renderErrors("firstName")}
                    <TextField
                        id="last-name"
                        name="lastName"
                        label={intl.formatMessage({
                            id: "ADMIN_USERS.CREATE_FORM.LAST_NAME_FIELD.LABEL"
                        })}
                        className={classes.textField}
                        margin="normal"
                        variant="filled"
                        fullWidth
                        {...formik.getFieldProps("lastName")}
                        error={checkForError("lastName")}
                    />
                    {renderErrors("lastName")}
                    <InputMask
                        name="phone"
                        mask="999-9999999"
                        {...formik.getFieldProps("phone")}
                    >
                        {() => (
                            <TextField
                                id="phone"
                                label={intl.formatMessage({
                                    id: "ADMIN_USERS.CREATE_FORM.MOBILE_FIELD.LABEL"
                                })}
                                className={classes.textField}
                                margin="normal"
                                variant="filled"
                                fullWidth
                                error={checkForError("phone")}
                                inputProps={{
                                    className: classes.textRight
                                }}
                                dir="ltr"
                            />
                        )}
                    </InputMask>
                    {renderErrors("phone")}
                    <TextField
                        id="email"
                        name="email"
                        label={intl.formatMessage({
                            id: "ADMIN_USERS.CREATE_FORM.EMAIL_FIELD.LABEL"
                        })}
                        className={classes.textField}
                        margin="normal"
                        variant="filled"
                        fullWidth
                        {...formik.getFieldProps("email")}
                        error={checkForError("email")}
                        inputProps={{
                            className: classes.textRight
                        }}
                        dir="ltr"
                    />
                    {renderErrors("email")}
                    <TextField
                        id="extra-email"
                        name="extraEmail"
                        label={intl.formatMessage({
                            id: "ADMIN_USERS.CREATE_FORM.EXTRA_EMAIL_FIELD.LABEL"
                        })}
                        className={classes.textField}
                        margin="normal"
                        variant="filled"
                        fullWidth
                        {...formik.getFieldProps("extraEmail")}
                        error={checkForError("extraEmail")}
                        inputProps={{
                            className: classes.textRight
                        }}
                        dir="ltr"
                    />
                    {renderErrors("extraEmail")}
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={he}>
                        <DatePicker
                            autoOk
                            clearable
                            format="dd/MM/yyyy"
                            value={formik.values.startDate}
                            onChange={value => {
                                formik.setFieldValue("startDate", value)
                            }}
                            TextFieldComponent={({ helperText, InputProps, ...props }) => (
                                <TextField
                                    id="start-date"
                                    label={intl.formatMessage({
                                        id: "ADMIN_USERS.CREATE_FORM.START_DATE_FIELD.LABEL"
                                    })}
                                    className={classes.textField}
                                    {...props}
                                    margin="normal"
                                    variant="filled"
                                    fullWidth
                                />
                            )}
                        />
                    </MuiPickersUtilsProvider>
                    <TextField
                        id="invoice"
                        name="invoice"
                        label={intl.formatMessage({
                            id: "ADMIN_USERS.CREATE_FORM.INVOICE_FIELD.LABEL"
                        })}
                        className={classes.textField}
                        margin="normal"
                        variant="filled"
                        fullWidth
                        {...formik.getFieldProps("invoice")}
                        error={checkForError("invoice")}
                        inputProps={{
                            className: classes.textRight
                        }}
                    />
                    {renderErrors("invoice")}
                    <TextField
                        id="externalID"
                        name="externalID"
                        label={intl.formatMessage({
                            id: "ADMIN_USERS.CREATE_FORM.EXTERNAL_ID_FIELD.LABEL"
                        })}
                        className={classes.textField}
                        margin="normal"
                        variant="filled"
                        fullWidth
                        {...formik.getFieldProps("externalID")}
                        error={checkForError("externalID")}
                        inputProps={{
                            className: classes.textRight
                        }}
                    />
                    {renderErrors("externalID")}
                    {["admin","owner", "customer"].includes(formVariant) && (
                        <>

                            {formik.values.role !== "Admin" && formik.values.role !== "Owner"  ? (
                                <>
                                    <InputMask
                                        name="permanentCode"
                                        mask="9 9 9 9 9 9"
                                        {...formik.getFieldProps("permanentCode")}
                                    >
                                        {() => (
                                            <TextField
                                                id="permanentCode"
                                                label={intl.formatMessage({
                                                    id: "ADMIN_USERS.CREATE_FORM.PERMANENT_CODE_FIELD.LABEL"
                                                })}
                                                className={classes.textField}
                                                margin="normal"
                                                variant="filled"
                                                fullWidth
                                                error={checkForError("permanentCode")}
                                                inputProps={{
                                                    className: classes.textRight
                                                }}
                                                dir="ltr"
                                            />
                                        )}
                                    </InputMask>
                                    {renderErrors("permanentCode")}
                                </>
                            ) : (
                                <div />
                            )}
                            
                        </>
                    )}
                    <FormControl
                        variant="filled"
                        className={classes.textField}
                        fullWidth
                        error={checkForError("role")}
                    >
                        <InputLabel htmlFor="role-select">
                            <FormattedMessage id="ADMIN_USERS.CREATE_FORM.ROLE_FIELD.LABEL"/>
                        </InputLabel>
                        <Select
                            input={<FilledInput name="role" id="role-select"/>}
                            {...formik.getFieldProps("role")}
                        >
                            {allUserRoles.map(role => (
                                <MenuItem key={role} value={role}>
                                    <FormattedMessage id={getRoleID(role)}/>
                                </MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                    {renderErrors("role")}
                    {formVariant === "admin" ||formVariant === "owner"  ? (
                        <>
                            {customerUserRoles.includes(formik.values.role) ? (
                                <>
                                    <FormControl
                                        variant="filled"
                                        className={classes.textField}
                                        fullWidth
                                        error={checkForError("customer")}
                                    >
                                        <InputLabel htmlFor="role-select">
                                            <FormattedMessage id="ADMIN_USERS.CREATE_FORM.CUSTOMER_FIELD.LABEL"/>
                                        </InputLabel>
                                        <Select
                                            {...formik.getFieldProps("customer")}
                                            onChange={event => {
                                                formik.setFieldValue("customer", event.target.value)
                                                formik.setFieldValue("locations", [])
                                                formik.setFieldValue("department", "")
                                            }}
                                            input={<FilledInput name="customer" id="role-select"/>}
                                        >
                                            {allCustomers.map(customer => (
                                                <MenuItem
                                                    key={customer.CustomerID}
                                                    value={customer.CustomerID}
                                                >
                                                    {customer.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {renderErrors("customer")}
                                    {renderLocationAndDepartmentFields()}
                                </>
                            ) : supplierUserRoles.includes(formik.values.role) ? (
                                <>
                                    <FormControl
                                        variant="filled"
                                        className={classes.textField}
                                        fullWidth
                                        error={checkForError("supplier")}
                                    >
                                        <InputLabel htmlFor="role-select">
                                            <FormattedMessage id="ADMIN_USERS.CREATE_FORM.SUPPLIER_FIELD.LABEL"/>
                                        </InputLabel>
                                        <Select
                                            {...formik.getFieldProps("supplier")}
                                            input={<FilledInput name="supplier" id="role-select"/>}
                                        >
                                            {allSuppliers.map(supplier => (
                                                <MenuItem
                                                    key={supplier.SupplierID}
                                                    value={supplier.SupplierID}
                                                >
                                                    {supplier.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {renderErrors("supplier")}
                                    <div style={{height: 136.88}}/>
                                </>
                            ) : (
                                <div style={{height: 205.32}}/>
                            )}
                        </>
                    ) : (
                        formVariant === "customer" && renderLocationAndDepartmentFields()
                    )}

                    <FormControlLabel
                        style={{ margin: "0 auto" }}
                        control={
                            <Checkbox
                                checked={formik.values.companyCredit}
                                name="companyCredit"
                                color="secondary"
                                disabled={disableCompanyCreditCheckbox ?? true}
                                {...formik.getFieldProps("companyCredit")}
                            />
                        }
                        label={intl.formatMessage({
                            id: "ADMIN_USERS.CREATE_FORM.COMPANY_CREDIT_FIELD.LABEL"
                        })}
                        labelPlacement="end"
                    />
                    {formik.values.companyCredit &&
                        <FormControl
                            variant="filled"
                            className={classes.textField}
                            fullWidth
                            error={checkForError("limitType")}
                        >
                            <InputLabel htmlFor="limitType-select">
                                <FormattedMessage id="ADMIN_USERS.CREATE_FORM.LIMIT_TYPE_FIELD.LABEL"/>
                            </InputLabel>
                            <Select
                                input={<FilledInput name="limitType" id="limitType-select"/>}
                                {...formik.getFieldProps("limitType")}
                            >
                                {limitTypes.map(limitType => (
                                    <MenuItem key={limitType} value={limitType}>
                                        <FormattedMessage id={getLimitTypeID(limitType)}/>
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>}

                    {renderErrors("invoice")}
                    {formik.values.companyCredit && formik.values.limitType !== "No limitation" &&
                        <TextField
                            id="limitValue"
                            name="limitValue"
                            label={intl.formatMessage({
                                id: "ADMIN_USERS.CREATE_FORM.LIMIT_VALUE_FIELD.LABEL"
                            })}
                            className={classes.textField}
                            margin="normal"
                            variant="filled"
                            fullWidth
                            {...formik.getFieldProps("limitValue")}
                            error={checkForError("limitValue")}
                            inputProps={{
                                className: classes.textRight
                            }}
                        />}


                    <div className={classes.buttonRow}>
                        {["admin","owner", "customer"].includes(formVariant) && (
                            <>
                                <Button
                                    variant="outlined"
                                    type="button"
                                    size="large"
                                    color="secondary"
                                    disabled={formik.isSubmitting || IsOwnerUserAndEditorNotOwner || IsAdminUserAndEditorNotOwner || isRoleNotAvailable()}
                                    onClick={() =>
                                        userData.Active
                                            ? setDeactivateDialogData({show: true})
                                            : handleAcceptChangeActive()
                                    }
                                >
                                    <FormattedMessage
                                        id={
                                            userData.Active
                                                ? "CREATE_FORM.FREEZE_BUTTON"
                                                : "CREATE_FORM.UNFREEZE_BUTTON"
                                        }
                                    />
                                </Button>


                                {userData.IsLocked && (
                                    <Button
                                        variant="outlined"
                                        type="button"
                                        size="large"
                                        color="secondary"
                                        className={classes.unlockButton}
                                        disabled={formik.isSubmitting || IsOwnerUserAndEditorNotOwner || IsAdminUserAndEditorNotOwner || isRoleNotAvailable()}
                                        onClick={handleUnlock}
                                    >
                                        <LockOpenIcon/>
                                    </Button>
                                )}
                            </>
                        )}
                        <Button
                            variant="contained"
                            type="submit"
                            size="large"
                            color="secondary"
                            className={classes.saveButton}
                            disabled={formik.isSubmitting || IsOwnerUserAndEditorNotOwner || IsAdminUserAndEditorNotOwner || isRoleNotAvailable()}
                        >
                            <FormattedMessage id="CREATE_FORM.SUBMIT_BUTTON"/>
                            {formik.isSubmitting && (
                                <span className="ml-1 spinner spinner-white"></span>
                            )}
                        </Button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default injectIntl(UserDetails)
